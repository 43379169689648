<template>
  <!--<div class="swiper-container">
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <img src="../assets/image/carousel01.jpg" alt="images">
      </div>
      <div class="swiper-slide">
        <img src="../assets/image/carousel02.jpg" alt="images">
      </div>
      &lt;!&ndash; <div class="swiper-slide">
        <img src="../assets/image/carousel03.JPG" alt="images">
      </div>&ndash;&gt;
      <div class="swiper-slide">
        <img src="../assets/image/carousel04.jpg" alt="images">
      </div>
      <div class="swiper-slide">
        <img src="../assets/image/carousel05.jpg" alt="images">
      </div>
    </div>
    <div class="swiper-pagination" />
    <div class="swiper-button-prev" />
    <div class="swiper-button-next" />
  </div>-->
  <div class="swipeHeader">
    <el-carousel :interval="4000" type="card" height="250px">
      <el-carousel-item v-for="item in items" :key="item.id">
        <img :src="item.url" alt="picture" class="image">
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: 'SwipeHeader',
  data() {
    return {
      items: [
        { id: 1, url: require('@/assets/image/carousel01.jpg') },
        { id: 2, url: require('@/assets/image/carousel02.jpg') },
        { id: 3, url: require('@/assets/image/carousel03.jpg') },
        { id: 4, url: require('@/assets/image/carousel04.jpg') },
        { id: 5, url: require('@/assets/image/carousel05.jpg') }
      ]
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style scoped>
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
</style>
