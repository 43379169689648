<template>
  <div class="footer">
    <p class="footerPart">Copyright © <a href="https://www.nemew.com/">neMew</a> (2021-2022)</p>
    <p class="footerPart"><a href="https://beian.miit.gov.cn/#/Integrated/index">蜀ICP备2021029165号-1</a></p>
    <p class="footerPart"><a href="#">联系作者~</a></p>
  </div>
</template>
<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
  .footer {
    width: 100%;
    background-color: #87ddff;
    padding: 20px;
    bottom: 0;
    font-family: "Times New Roman", Times, serif;
  }
  .footerPart a{
    text-decoration: none;
  }
</style>
