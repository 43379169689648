<template>
  <div class="header">
    <div class="topSide">
      <div class="webLog">
        <a href="https://www.nemew.com/">
          <img src="../assets/image/Logo.png" alt="Logo" width="100" height="44">
        </a>
      </div>
      <div class="navBar">
        <a href="#">首页</a>
        <a href="#">动态</a>
        <a href="#">日志</a>
        <a href="#">留言</a>
        <a href="#">关于</a>
        <a href="#">你猜</a>
        <el-input
          v-model="input"
          placeholder="请输入搜索内容"
          clearable
          style="width: 200px; padding: 8px 0 8px 16px"
        />
        <el-button
          icon="el-icon-search"
          size="mini"
          circle
          style="margin-right: 20px;margin-top: 8px; width: 40px; height: 40px"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      input: ''
    }
  }
}
</script>

<style scoped>
  .topSide {
    background-color: mediumpurple;
    text-align: center;
    width: 100%;
    margin: 0;
    position: sticky;
    top: 0;
  }

  .webLog {
    display: flex;
    float: left;
    padding: 8px;
    margin-left: 20px;
    justify-content: flex-start;
  }

  .navBar {
    display: flex;
    justify-content: flex-end;
  }

  .navBar a {
    color: #4AB7BD;
    text-decoration: none;
    padding: 16px;
    text-align: center;
  }

  .navBar a:hover {
    background-color: #777777;
    color: #13CE66;
  }
</style>

