<template>
  <div class="home">
    <TopPart />
    <div class="mainPart">
      <div class="centerPart">
        <el-card class="center-card" shadow="always">
          <h1>关于本网站</h1>
          <p>
            一直都在一些论坛博客发表自己的一些学习见解以及学习笔记，希望在支持自己复习巩固的同时也能帮助到他人，但由于平台越来越多，类型也变得多样起来。同时也到了找工作的时候，希望能搭建一个个人网站记录生活和展示自己的技术栈，增加自己的项目经验。
          </p>
          <img src="../assets/image/personalIntroduce.jpg" alt="个人介绍">
          <p>
            这个网页于2022年五月二十七日正式开始建设，之前用的wordpress来搭建自己的博客，但觉得由自己亲自设计实践更加有成就感，便开始了小白的探索之旅。敬请期待！
          </p>
        </el-card>
      </div>
      <div class="rightPart">
        <el-card class="right-cart">111</el-card>
      </div>
    </div>
  </div>

</template>

<script>
import TopPart from '../components/TopPart'
export default {
  name: 'Home',
  components: { TopPart }
}
</script>

<style scoped>
  .mainPart {
    display: flex;
    flex-wrap: wrap;
  }

  .centerPart {
    flex: 75%;
    padding: 20px;
  }

  .rightPart {
    flex: 25%;
    padding: 20px;
  }

  .text {
    font-size: 14px;
  }

  .item {
    padding: 18px 0;
  }

  .center-card, .right-cart {
    width: 100%;
  }

  img {
    max-width: 100%;
    height: auto;
  }
</style>
