import Vue from 'vue'
import {
  Button,
  Dialog,
  Input,
  Card,
  Carousel,
  CarouselItem
} from 'element-ui'

Vue.use(Button)
Vue.use(Dialog)
Vue.use(Input)
Vue.use(Card)
Vue.use(Carousel)
Vue.use(CarouselItem)
