import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import store from './store'
import './plugins/vueAS'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.directive('title', {
  inserted: function(el, binding) {
    document.title = el.dataset.title
  }
})
