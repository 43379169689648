<template>
  <div class="topPart">
    <div class="left">
      <SwipeHeader />
    </div>
    <div class="right">
      <el-card class="box-card">
        <div class="headPhoto">
          <img id="headPortrait" src="../assets/image/headLogo.png" alt="head portrait">
        </div>
        <p id="nameFont">n e M e w</p>
        <p class="shareSentence">你终究会成为你正在成为的人，你的每一个选择都是来自你人生意义的诘问。</p>
<!--        <p class="shareSentence">个选择都是来自你人生意义的诘问。</p>-->
        <p style="margin: 0; float: right">——毛姆</p>
      </el-card>
    </div>
  </div>
</template>

<script>
import SwipeHeader from './SwipeHeader'
export default {
  name: 'TopPart',
  components: {
    SwipeHeader
  }
}
</script>

<style scoped>
  .topPart {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .left {
    flex: 75%;
    padding: 20px;
  }
  .right{
    flex: 25%;
    padding: 20px;
  }
  .text {
    font-size: 14px;
  }

  .item {
    padding: 18px 0;
  }

  .box-card {
    width: 100%;
    height: 300px;
    background-image: url('../assets/image/headPhotoBg.png');
    background-size:400px 200px;
    background-position: top;
    background-repeat: no-repeat;
  }
  .headPhoto {
    border-radius: 30px;
  }
  .shareSentence {
    padding: 0;
    margin: 5px 0 0 0;
  }
  #headPortrait {
    border-radius: 35px;
  }
  #nameFont {
    font-family: "Times New Roman", Times, serif;
    font-size: 20px;
    margin: 0;
  }
</style>
