<template>
  <div id="app">
    <Header />
    <router-view class="mainSection" />
    <Footer />
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer'

export default {
  name: 'App',
  components: {
    Footer,
    Header
  },
  data() {
    return {
      title: 'neMew的臆想blog',
      baseUrl: 'index'
    }
  },
  created() {
    document.title = this.title
    document.baseURI = this.baseUrl
  }
}
</script>

<style>
  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    background-image: url('./assets/image/background.jpg');
  }

  . {
    box-sizing: border-box;
  }

  body {
    margin: 0;
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  #nav a {
    font-weight: bold;
    color: #2c3e50;
  }

</style>
