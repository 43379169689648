import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import Vue from 'vue'
import Swiper from 'swiper'

Vue.use(
  VueAwesomeSwiper,
  Swiper
)

import 'swiper/dist/css/swiper.css'

